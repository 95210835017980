// Suggest a Video page

.page__suggest-title {
  .page__title{
    margin-top: 100px;
    text-align: left;
    color: $orange;
    font-size: 46px;
    @include breakpoint($screen-tablet){
      font-size: 56px;
    }
    font-family: $sofiapro;
  }
  .quicktabs_video_suggestions {
    .pane__title {
      font-size: $font-size-large;
    }
    .quicktabs-tabs {
      padding: 0;
      margin-bottom: 0;

      li {
        padding: 0;
      }

      .active .btn {
        @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
      }

      li:last-child{
        float: right;
        margin-top: 5px;
        a{
          @include btn-orange();
        }
      }
      .suggest_video__suggest_link {
        font-size: $font-size-large;
      }
    }
    .quicktabs_main {
      padding-top: 15px;
      border-top: 1px solid $blue-dark;
    }
  }

  .suggest_video__item {
    margin-top: 75px;
    color: $blue-dark;
    .suggest_video__title {
      color: $orange;
    }
    .tagline {
      font-size: .5em;
      color: $blue;
    }
    .flag img {
      width: unset;
      margin-right: 15px;
    }
    .suggest_video__footer {
      font-size: $font-size-large;
      line-height: 1em;
      padding: 12px 25px 10px;
      margin-top: 10px;
      background-color: $silver;
    }
    .suggest_video__label {
      color: $blue;
      font-size: $font-size-large;
      margin-bottom: 0;
      margin-top: 0;
    }
    .suggest_video__count_item {
      color: $orange;
    }
    .suggest_video__count_wait {
      display: none;
    }
    .suggest_video__btn {
      text-align: right;

      a {
        color: $blue-dark;
      }
      .glyphicon {
        color: $orange;
        vertical-align: text-top;
        top: -2px;
      }
    }
  }

  .views-row-1 .suggest_video__item {
    margin-top: 25px;
  }
  .suggest_video_form {
    margin-bottom: 25px;
    &__quote {
      color: $orange;
      font-size: 4em;
      line-height: 1em;
    }
    .form-item-title {
      float: left;
      width: 50%;
      margin-right: 5px;
      margin-left: -10px;
      @include breakpoint($screen-sm-min) {
        width: 60%;
        margin-left: 0;
      }
      @include breakpoint($screen-md-min) {
        width: 55%;
      }
      @include breakpoint($screen-lg-min) {
        width: 65%;
      }
    }
    &__title_prefix,
    &__title_suffix {
      display: block;
      float: left;
    }
    .field-name-field-cc-suggest-description {
      clear: both;
    }
  }
}

.pane__views_panes__suggestions_panel_pane_4{
  margin-top: 92px;
  h2.pane__title{
    font-size: 24px;
    color: $blue-dark;
    font-family: $sofiapro;
    font-weight: 600;
  }
  .views-row{
    margin-top: 20px;
    margin-bottom: 0;
    h4.field-content{
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
      a{
        font-size: 18px;
        font-weight: 600;
      }
    }
    span{
      font-size: 14px;
    }
  }
}