// Homepage.

.front{
  h1.page__title{
    margin-top: 100px;
    text-align: center;
    color: $orange;
    font-size: 46px;
    @include breakpoint($screen-tablet){
      font-size: 56px;
    }
    font-family: $sofiapro;
  }
  .pane__subtitle_join_button,
  .field_subtitle__custom_display {
    @include breakpoint($screen-tablet){
      width: 60%;
      text-align: center;
      margin: 10px auto ;
    }
    font-size: $font-size-h4;
    line-height: $font-size-h3;
    font-weight: 300;
  }
  .pane__subtitle_join_button {
    margin-bottom: 10px;
    padding-bottom: 50px;
    background: url('../images/decorative/scroll_arrow.png') no-repeat;
    background-position-x: center;
    background-position-y: bottom;
  }
}

.testimonial--front{
  background: $white;
  color: $text-color;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
  .testimonial__copy{
    .testimonial__img{
      padding-bottom: 15px;
      @include breakpoint($screen-phone){
        padding-bottom: 0;
        max-width: 120px;
        float: left;
        padding-right: 15px;
      }
    }
    .testimonial__quote{
      font-family: $sofiapro;
      color: $orange;
      font-size: $font-size-h5;
    }
    .testimonial__customer{
      margin-top: 10px;
      font-size: $font-size-small;
      color: $gray-base;
      font-weight: bold;
      @include breakpoint($screen-desktop){
        padding-left: 120px;
      }
      .testimonial__name{
        display: inline-block;
        &:after{
          content:",";
        }
      }
      .testimonial__profession{
        display: inline-block;
        &:after{
          content:",";
        }
      }
      .testimonial__org{
        display: inline-block;
      }
    }
  }
}

.home__sections_teaser{
  margin-top: 60px;
  margin-bottom: 30px;
  .container{
    padding-left: 0;
    padding-right: 0;
    @include breakpoint($screen-tablet){
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @include breakpoint($screen-tablet){
    margin-top: 75px;
    margin-bottom: 50px;
  }
  .home__sections_teaser_videos,
  .home__sections_teaser_cutouts,
  .home__sections_teaser_ea{
    a.home__sections__link{
      padding-top: 10px;
      padding-bottom: 20px;
      cursor: pointer;
      @include breakpoint($screen-tablet){
        padding-top: 50px;
        padding-bottom: 36px;
      }
    }
    a.home__sections__link-img{
      cursor: pointer;
      opacity: 1;
      display: block;
      margin: 0 auto;
      padding-top: 20px;
      text-align: center;
      transition: opacity .2s ease-in;
      img{
        max-width: 100px;
      }
      @include breakpoint($screen-tablet){
        position: absolute;
        top: -52px;
        left: 32%;
        max-width: 130px;
      }
    }
  }
  .home__sections_teaser_videos a.home__sections__link-img{
    background-color: $orange;
    @include breakpoint($screen-tablet){
      background-color: transparent;
    }
  }
  .home__sections_teaser_cutouts a.home__sections__link-img{
    background-color: $blue-dark;
    @include breakpoint($screen-tablet){
      background-color: transparent;
    }
  }
  .home__sections_teaser_ea a.home__sections__link-img{
    background-color: $green;
    @include breakpoint($screen-tablet){
      background-color: transparent;
    }
  }

  // Special placement for video.
  .home__sections_teaser_videos img{
    top: 30px;
    @include breakpoint($screen-tablet){
      top: -44px;
    }
  }
}

.container.home__timeline .col-md-4:last-child{
  h2{
    &:after{
      content: " ";
    }
  }
}

.home__timeline{
  margin-bottom: 40px;
  .col-md-4{
    margin-top: 80px;
    margin-bottom: 20px;
    @include breakpoint($screen-desktop){
      margin-bottom: 0px;
    }
  }
  h2{
    font-family: $sofiapro;
    color: $blue-dark;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1em;
    @include breakpoint($screen-desktop){
      &.home__timeline_year{
        &:after{
          content: "\2192";
          float: right;
        }
      }
    }
  }

  img{
    display: block;
    margin: 0 auto;
    max-width: 60%;
  }
  .home__timeline_header {
    color: $orange;
  }
  .home__timeline_copy{
    margin-top: $grid-gutter-width;
    text-align: center;
    @include breakpoint($screen-desktop){
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }
}

.home__products{
  h3{
    text-align: center;
    color: $orange;
    font-family: $sofiapro;
    font-weight: 600;
    font-size: 21px;
    padding-bottom: 24px;
    background: url('../images/decorative/scroll_arrow.png') no-repeat;
    background-position-x: center;
    background-position-y: bottom;
  }
}

#videos,
#cutouts,
#explainer{
  &:before{
    display: block;
    content: " ";
    margin-top: -35px;
    height: 35px;
    visibility: hidden;
  }
}
.rooster_home_sections{
  z-index: 9999;
  @include breakpoint($screen-tablet){
    background: linear-gradient(
      to right,
      $orange 0%,
      $orange 50%,
      $green 50%,
      $green 100%
    );
  }
  .home__sections.container{
    padding-left: 0;
    padding-right: 0;
    @include breakpoint($screen-tablet){
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .home__sections__link{
    background: $orange;
    display: block;
    color: $white;
    font-family: $sofiapro;
    font-weight: 600;
    font-size: 21px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    &:hover{
      text-decoration: underline;
    }
  }
  .home__sections__link_videos{
    background: $orange;
  }
  .home__sections__link_cutouts{
    background: $blue-dark;
  }
  .home__sections__link_explanation{
    background: $green;
  }
  .container > [class^="col-"],
  .container > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.rooster_home_videos,
.rooster_home_cutouts,
.rooster_home_ea{
  // min-height: 100vh;
}

.rooster_home_videos,
.rooster_home_cutouts,
.rooster_home_ea,
.rooster_home_features{
  h2,
  h3,
  .btn{
    font-family: $sofiapro;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p{
    font-size: $font-size-large;
    line-height: $font-size-x-large;
    margin-bottom: 20px;
  }
}

.rooster_home_videos{
  background: $orange;
  color: $white;
  padding-bottom: 20px;
  @include breakpoint($screen-tablet){
    padding-bottom: 35px;
  }
  .home__products_cutouts{
    display: none;
    @include breakpoint($screen-tablet){
      display: block;
    }
    align-self: flex-end;
    text-align: center;
    padding-bottom: 14px;
    padding-bottom: 14px;
    background: url('../images/decorative/scroll_arrow_white.png') no-repeat;
    background-position-x: center;
    background-position-y: bottom;
  }
  .btn{
    color: $orange;
  }
}

.rooster_home_cutouts{
  background: $blue-dark;
  color: $white;
  padding-bottom: 20px;
    @include breakpoint($screen-tablet){
      padding-bottom: 65px;
    }
  .home__products_cutouts{
    display: none;
    @include breakpoint($screen-tablet){
      display: block;
    }
    align-self: flex-end;
    text-align: center;
    padding-bottom: 14px;
    padding-bottom: 14px;
    background: url('../images/decorative/scroll_arrow_white.png') no-repeat;
    background-position-x: center;
    background-position-y: bottom;
  }
}

.rooster_home_ea{
  background: $green;
  color: $white;
  padding-bottom:  0px;
  @include breakpoint($screen-tablet){
    min-height: 100vh;
  }
  .btn{
    color: $green;
  }
}

// Offsetting headers.

#videos{
  &:before {
    content: "";
    display: block;
    padding-top: 130px; /* header height + empty space */
    margin-top: -130px; /* header height to degrees padding’s space */
  }
}

#cutouts{
  position: relative;
  #anchor__cutouts{
    position: absolute;
    top: -120px;
    display: block;
    height: 1px;
    background: transparent;
    @include breakpoint($screen-tablet){
      top: 0px;
    }
  }
}

#explainer{
  position: relative;
  #anchor__ea{
    position: absolute;
    top: -120px;
    display: block;
    height: 1px;
    background: transparent;
    @include breakpoint($screen-tablet){
      top: 0px;
    }
  }
}

@include breakpoint($screen-tablet){
  #videos,
  #cutouts,
  #explainer{
    &:before{
      content: "";
      display: block;
      padding-top: 0; /* header height + empty space */
      margin-top:  0; /* header height to degrees padding’s space */
    }
  }
  #explainer{
    &:before {
      padding-top: 60px; /* header height + empty space */
      margin-top: -60px; /* header height to degrees padding’s space */
    }
  }
}

.rooster_home_features{
  color: $white;
  text-align: center;
  @include breakpoint($screen-tablet){
    background: linear-gradient(
      to right,
      $orange 0%,
      $orange 50%,
      $green 50%,
      $green 100%
    );
  }
  .container{
    padding-left: 0;
    padding-right: 0;
    @include breakpoint($screen-tablet){
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  ul li{
    margin-bottom: 10px;
  }
  .home__features_video{
    padding-top: 80px;
    padding-bottom: 60px;
    background: $orange;
  }
  .home__features_cutouts{
    padding-top: 80px;
    padding-bottom: 90px;
    background: $blue-dark;
  }
  .home__features_ea{
    padding-top: 80px;
    padding-bottom: 60px;
    background: $green;
  }
  // @TODO remove when proper images are in
  img{
    max-width: 100px;
  }
}

.container.home__videos,
.container.home__cutouts,
.container.home__ea{
  @include breakpoint($screen-tablet){
    min-height: 68vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.container.home__videos{
  @include breakpoint($screen-tablet){
    min-height: 56vh;
  }
  @include breakpoint($screen-desktop){
    min-height: 86vh;
  }
}

.container.home__cutouts{
  @include breakpoint($screen-tablet){
    min-height: 56vh;
  }
  @include breakpoint($screen-desktop){
    min-height: 86vh;
  }
}

.pane__custom__email_sign_up_form{
  .email-signup__image{
    background-image: url(../images/decorative/newsletter_mailbox.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 250px;
    @include breakpoint($screen-tablet){
      height: 350px;
    }
  }

}
