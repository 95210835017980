// Email Signup

// .pane__custom__email_sign_up_form{
//   background-color: $well-bg;  
// }

.email_sign_up_form{
  background-color: $silver;
  padding-top: 68px;
  padding-bottom: 48px;
  text-align: center;
  @include breakpoint($screen-desktop) {
    text-align: left;
  }
  h2{
    @include sidebar-heading;
    font-size: 34px;
  }
  .email-signup__subtitle{
    font-family: $sofiapro;
    font-weight: 600;
    font-size: $font-size-h4;
    line-height: $font-size-h3;
  }
  .email-signup__form{
  }
  
  .email-signup__input{
    @include text-input;
    display: block;
    font-weight: 600;
    margin: 24px 0 12px;
    text-align: center;
    width: 100%;
  }
  .ck_form.ck_naked{
    margin-top: 0 !important;
    .ck_form_fields{
      padding-top: 0;      
      padding-left: 0;
    }
  }
}