// Contact page

.page__5214 {
  .white_have_feedback {
    padding: 35px 0;
    margin-top: 25px;

    .pane__title {
      color: black;
    }
  }
  .pane__custom__contact_header {
    padding: 35px 0;
  }
}