// Tools promo.

#mini-panel-promo_tools{
  padding-top: 90px;
  padding-bottom: 90px;
  background: $blue-dark;
  color: $white;
  h2{
    font-family: $sofiapro;
    font-size: $font-size-h1;
    font-weight: 600;
    line-height: $font-size-h1;
  }
  p{
    font-size: $font-size-h4;    
  }
}