// Landing page.

.landing_page{
  .pane__node_title h1 {
    margin-bottom: 15px;
  }

  .field-collection-container,
  .field-collection-view{
    border-bottom: 0;
  }
  .landing__features__list{
    .field_feature_callout__item{
      padding-top: 30px;
      margin-bottom: 60px;
      background: url(/sites/all/themes/commoncraft/images/decorative/landing-burst.png) no-repeat;
      background-position: center top;
      background-size: 30px;
    }
  }
  .field_landing_testimonial__items{
    @include breakpoint($screen-tablet) {
      padding-left: 60px;
      padding-right: 60px;
    }
    .field_testimonial_body{
      font-size: $font-size-large;
      font-family: $creteround;
      font-style: italic;
      line-height: 1.5;
      color: $gray-light;
      margin-bottom: 1em;
      .field_testimonial_body__item div{
        &:before{
          content: open-quote;
        }
        &:after{
          content: close-quote;
        }
      }
      @include breakpoint($screen-tablet) {
        min-height: 200px;
      }
    }
    .field_testimonial_headshot{
      max-width: 100px;
      margin: 0 auto;
    }
    .field_testimonial_name{
      text-align: center;
      color: $orange;
    }
    .field_testimonial_job{
      text-align: center;
    }
  }
  .pane__entity_field__field_buy_button_embed {
    padding: 10px 0;
  }
  .field_footer_button {
    margin-bottom: 15px;
  }
}

.node-type-landing-page .main__content{
  border-bottom: 1px solid #f5f5f5;
}

// Landing Page panels
.pane__panels_mini__landing_page_head {
  border-bottom: 1px solid #DDD;
  padding-bottom: 30px;

  .pane__entity_field__field_header_link {
    text-align: center;
    margin-top: 15px;
  }
}

// Remove border on join page node-9847
.page-node-9847 .pane__panels_mini__landing_page_head{
  border: none;
}

.pane__panels_mini__landing_header_first {
  text-align: center;
  @media(min-width: $screen-desktop){
    text-align: left;
  }

  .field_landing_support {
    font-size: $font-size-h4;
    font-weight: 300;
    line-height: $line-height-base;
    color: $gray-light;
    margin-bottom: $margin-bottom-base;
    @media(min-width: $screen-desktop){
      font-size: $font-size-large;
    }
  }
  #mini-panel-landing_header_first {
    a.btn {
      margin-top: 0;
    }
  }
}

.landing__features__heading{
  padding-top: 3em;
  padding-bottom: 2em;

  h2{
    color: $orange;
    font-weight: 600;
    text-align: center;
  }
}

// Wrapper for shopify buttons.
.landing_page{
  // On large screens.
  .inline-container{
    display: flex;
    justify-content: center;
  }
  .inline-embed{
    @include breakpoint($screen-md){
      display: inline-block;
      margin-right: 25px;
    }
  }
}

.field_buy_button_embed__item,
.field_landing_popular_videos {
  .landing__features__heading{
    padding-top: .5em;
    padding-bottom: 1em;
  }
  padding: 10px 0;
  h2.content__title{
    font-size: 16px;
  }
}

.pane__panels_mini__landing_page_feature_callout{
  border-bottom: 1px solid #DDD;

  .field-collection-view{
    margin: 0;

    @include breakpoint($screen-sm){
      margin: 0 1em;
    }
  }

  .field_feature_callout__item{
    text-align: center;

    .field_callout_title{
      color: $blue-dark;
      font-weight: 600;
      margin-bottom: .5em;
    }
  }
}

.pane__entity_field__field_testimonial_heading{
  padding-top: 3em;
  padding-bottom: 2em;

  h2{
    color: white;
    font-weight: 600;
    text-align: center;
  }
}

.pane__panels_mini__landing_page_testimonials{
  padding-top: 68px;
  padding-bottom: 2em;
}

.pane__entity_field__field_landing_testimonial{
  text-align: center;
}

.pane__entity_field__field_faq_heading{
  padding-top: 3em;
  padding-bottom: 2em;

  h2{
    color: $orange;
    font-weight: 600;
    text-align: center;
  }
}

.pane__entity_field__field_landing_body{
  .field_landing_body{
    @include breakpoint($screen-sm){
      margin: 0 15%;
    }
    @include breakpoint($screen-md){
      margin: 0 20%;
    }
    h3{
      color: $blue-dark;
      font-weight: 600;
    }
  }
}

.field_bp_custom_top {
  margin-bottom: 100px;
}

.front-page-featured-videos {
  h2 {
    font-family: "Sofia Pro",sans-serif;
    color: #ee7729;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1em;
  }
}
