.pane__entity_field__field_cc_video_related_videos{
  background-color: $silver-light;
  padding-top: 24px;
  .pane__title{
    font-size: $font-size-base;
    font-weight: 600;
  }
}

.field_cc_video_related_videos__item{
  .field_cc_video_image__card{
    border: 1px solid #d7d7d7;
  }
  .content__title{
    font-size: $font-size-base;
  }
}
