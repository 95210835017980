// Buttons

.btn{
  margin-bottom: 8px;
  line-height: 1;
  padding-top: 8px;
  .glyphicon{
    font-size: 12px;
    margin-right: 8px;
  }
}

// Orange CC buttons
.btn-orange {
  @include button-variant($btn-orange-color, $btn-orange-bg, $btn-orange-border);
  border: none;
  border-radius: $btn-border-radius-base;
  font-size: $font-size-button;
  font-weight: 600;
  line-height: 1;
  padding: 14px;
  font-family: $sofiapro;
}

// Blue CC buttons
.btn-blue {
  @include button-variant($white, $blue, $btn-orange-border);
  border: none;
  border-radius: $btn-border-radius-base;
  font-size: $font-size-button;
  font-weight: 600;
  line-height: 1;
  padding: 14px;
  font-family: $sofiapro;
}

// Blue CC buttons
.btn-blue-inverse {
  @include button-variant($blue, $white, $blue);
  border: 1px solid $blue;
  border-radius: $btn-border-radius-base;
  font-size: $font-size-button;
  font-weight: 600;
  line-height: 1;
  padding: 14px;
  font-family: $sofiapro;
}

// Disabled CC buttons
.btn-disabled {
  @include button-variant($gray-dark, $gray-lighter, $gray-dark);
  border: none;
  border-radius: $btn-border-radius-base;
  font-size: $font-size-button;
  font-weight: 600;
  line-height: 1;
  padding: 14px;
  font-family: $sofiapro;
  &:hover {
    cursor: not-allowed;
    background-color: $gray-lighter;
  }
}

@mixin btn-orange{
  @include button-variant($btn-orange-color, $btn-orange-bg, $btn-orange-border);
  border: none;
  border-radius: $btn-border-radius-base;
  font-size: $font-size-button;
  font-weight: 600;
  line-height: 1;
  padding: 14px;
  font-family: $sofiapro;
}

// Search form btn.

.views-exposed-form .views-exposed-widget .btn {
  margin-top: 0;
}