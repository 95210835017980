// Cutouts landing.

.pane__panels_mini__cut_out_library_top_info,
.pane__panels_mini__page_video_cutout_landing{
  text-align: center;
  @media(min-width: $screen-desktop){
    text-align: left;
  }

  .pane__node_title h1{
    font-size: $landing-heading;
    margin-top: -2px;
  }

  .field_subtitle{
    font-size: $font-size-h4;
    font-weight: 300;
    line-height: $line-height-base;
    margin-bottom: $margin-bottom-base;
    @media(min-width: $screen-desktop){
      font-size: $font-size-large;
    }
  }

  .btn-orange{
    font-weight: 600;
    margin-right: 10px;
    margin-left: 0px;
  }
  .cutout_library__button--left,
  .video_cutout_landing__button--left{
    float: left;
  }
  .cutout_library__button--right,
  .video_cutout_landing__button--right{
    float: left;
  }
  .cutout_library__button--right{
    a{
      margin-top: 30px;
      margin-left: 10px;
      display: block;
      font-weight: bold;
    }
  }
  .pane__panels_mini__content--1{
    .pane__content{
      margin-top: 20px;
      margin-bottom: 20px;
      border: none;
    }
  }
  .pane__panels_mini__content--2{
    .auth_cut_out__library__header-right{
      text-align: center;
      .auth_cut_out__library__header-right-image{
        @include breakpoint($screen-tablet){
          padding-left: 160px;
          padding-right: 160px;
          padding-top: 20px;
        }
      }
    }
    .page_video_cutout_landing_body{
      @include breakpoint($screen-tablet){
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 40px;
      }
    }
    .cut_out_header_btn--second{
      text-align: center;
    }
    h2{
      color: $blue-dark;
      font-size: $font-size-h4;
      font-weight: 600;
    }
    p{
      margin-bottom: 0;
    }
  }
}

.view__video_topics_landing_list{
  // Now add each background for each of the list items using hard coded id.
  .term__wrapper .tid-365 header{
    background-image: url("../images/video_cat_icons/technology.svg");
  }
  .term__wrapper .tid-366 header{
    background-image: url("../images/video_cat_icons/socialmedia.svg");
  }
  .term__wrapper .tid-364 header{
    background-image: url("../images/video_cat_icons/netsafety.svg");
  }
  .term__wrapper .tid-363 header{
    background-image: url("../images/video_cat_icons/money.svg");
  }
  .term__wrapper .tid-362 header{
    background-image: url("../images/video_cat_icons/society.svg");
  }
  .term__wrapper .tid-826 header{
    background-image: url("../images/video_cat_icons/studyskills.svg");
  }
  // Now position each background.
  .term__wrapper .tid-365 header{
    background-size: 200px;
    background-position: left -90px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  .term__wrapper .tid-366 header{
    background-size: 200px;
    background-position: left -90px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  .term__wrapper .tid-364 header{
    background-size: 200px;
    background-position: left -90px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  .term__wrapper .tid-363 header{
    background-size: 200px;
    background-position: left -90px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  .term__wrapper .tid-362 header{
    background-size: 200px;
    background-position: left -90px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  .term__wrapper .tid-826 header{
    background-size: 200px;
    background-position: left -90px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  @media(min-width: $screen-tablet){
    // Now position each background for larger screens.
    .term__wrapper .tid-365 header{
      background-size: 260px;
      background-position: -30px center;
      background-repeat: no-repeat;
      padding-left: 200px;
      padding-top: 0;
    }
    .term__wrapper .tid-366 header{
      background-size: 260px;
      background-position: -30px center;
      background-repeat: no-repeat;
      padding-left: 200px;
      padding-top: 0;
    }
    .term__wrapper .tid-364 header{
      background-size: 260px;
      background-position: -30px center;
      background-repeat: no-repeat;
      padding-left: 200px;
      padding-top: 0;
    }
    .term__wrapper .tid-363 header{
      background-size: 260px;
      background-position: -30px center;
      background-repeat: no-repeat;
      padding-left: 200px;
      padding-top: 0;
    }
    .term__wrapper .tid-362 header{
      background-size: 260px;
      background-position: -30px center;
      background-repeat: no-repeat;
      padding-left: 200px;
      padding-top: 0;
    }
    .term__wrapper .tid-826 header{
      background-size: 260px;
      background-position: -30px center;
      background-repeat: no-repeat;
      padding-left: 200px;
      padding-top: 0;
    }
  }
  .term__link{
    display: block;
    margin-top: 20px;
  }
}


