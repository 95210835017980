// Follow Us.
.sidebar_follow_us{
  background-color: $blue-dark;
  color: $wh_white;
  text-align: center;
  
  .pane__content{
    padding: 20px 0;
  }
  
  .sidebar_follow_us__title{
    font-size: $font-size-large;
  }
  
  .sidebar_follow_us__links{
    font-size: 28px;
    
    a{
      color: $wh_white;
      display: inline-block;
      padding: 0 8px;
    }
  }
  
}