.promo_explainer_academy{
  background-color: $green;
  background-image: url(../images/ea_male_waist_hang_loose_blue.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 20% auto;
  color: #FFF;
  padding-top: 60px;
  padding-bottom: 120px;
  text-align: center;
  @include breakpoint($screen-tablet){
    background-size: 10% auto;
    background-image: url(../images/ea_male_waist_hang_loose_blue.png);
    min-height: 680px;
    padding-top: 100px;
    text-align: left;
  }
  @include breakpoint($screen-desktop){
    background-size: 10% auto;
    background-image: url(../images/ea_male_waist_hang_loose_blue.png);
    min-height: 500px;
    padding-top: 100px;
    text-align: left;
    .pane__custom{
      font-size: $font-size-large;
    }
    .pane__title{
      font-size: 48px;
      margin-bottom: 20px;
    }
  }
  .btn{
    margin-top: 20px;
    font-weight: 600;
    color: $green;
  }
  .promo_ea_quote{
    margin-top: 60px;
    @include breakpoint($screen-desktop){
      margin-top: 80px;
    }
    text-align: center;
    .ea_quote{
      margin-bottom: 20px;
      font-family: $sofiapro;
      font-style: italic;
      font-size: $font-size-h4;
    }
    .ea_quote_name{
      color: $green-dark;
      font-size: $font-size-base;
    }
  }
}