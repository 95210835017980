// Global headings styles.

// Headings
.small-heading{
  font-family: $sofiapro;
  font-size: $small-heading;
  font-weight: 600;
  line-height: 28px;
}

.large-heading{
  font-family: $sofiapro;
  font-size: $large-heading;
  font-weight: 600;
  line-height: ($large-heading * 1.25);
}

.sub-title{
  font-family: $sofiapro;
  font-size: $font-size-h2;
  font-weight: 600;
  line-height: ($font-size-h2 * 1.25);
  color: $gray-light;
}

.field_subtitle{
  @extend .sub-title;
}

// Page title.

h1.page__title{
  color: $blue-dark;
  font-family: $sofiapro;
  font-weight: 600;
}

//New front page title field
.field_bp_custom_title {
  .page__title {
    margin-top: 30px !important;
    margin-bottom: 70px;
  }
}

.page__title-reduced{
  font-size: $font-size-h1 !important;
}

// Sidebar Heading.
@mixin sidebar-heading{
  color: $orange;
  font-size: $heading-sidebar;
  font-weight: 600;
}
