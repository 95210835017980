// Video Library page main content

.video_library_header{
  text-align: center;
  @include breakpoint($screen-desktop){
    text-align: left;
  }
  h1.page__title{
    color: $orange;
  }
}

img.video-suggested{
  width: initial;
}

.video_library_category_header p{
  text-align: center;
  color: $blue-dark;
  font-size: $font-size-base;
  font-weight: 700;
}

.page-videolist{
  .pane__panels_mini__video_library_header{
    margin-bottom: 30px;
  }
  .content__title{
    font-size: $font-size-base;
    text-align: left;
  }
}

.header__buttons-anon{
  margin-top: 28px;
}

.pane__views_panes__video_latest_panel_pane_1{
  h2.pane__title{
    font-size: 16px;
  }
  h2.content__title{
    margin-top: 0;
    a{
      font-size: 18px;
    }
  }
}

.video_library_category_header{
  margin-bottom: 16px;
}

.video__library__header__btns{
  margin-top: 20px;
}

.view__rooster_video_library .view__header,
.video-page-listing {

  ul,
  ul.quicktabs-tabs{
    padding: 0;
    background: #f5f5f5;
    display: flex;
    justify-content: left;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    @media(min-width: $screen-desktop){
      justify-content: center;
      overflow-x: auto;
    }
    li{
      min-width: 120px;
      @media(min-width: $screen-desktop){
        min-width: auto;
      }
      text-align: center;
      width: 14.285%;
      a{
        padding-top: 64px;
        @media(min-width: $screen-desktop){
          padding-top: 86px;
        }
        @media(min-width: $screen-lg-desktop){
          padding-top: 110px;
        }
        color: $blue-dark;
        display: block;
        font-size: $font-size-small;
        font-weight: 600;
        padding-bottom: 16px;
        background-position: center 10px;
        background-repeat: no-repeat;
        // Now add each background for each of the list items using hard coded id. Designer will supply final cut images, just use placeholder for now.
        &.term-837,
        &#quicktabs-tab-cc_video_quicktab-0,
        &#quicktabs-tab-basic_member_video_picker-0{
          background-image: url("../images/video_cat_icons/all.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.term-365,
        &#quicktabs-tab-cc_video_quicktab-1,
        &#quicktabs-tab-basic_member_video_picker-1{
          background-image: url("../images/video_cat_icons/technology.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.term-366,
        &#quicktabs-tab-cc_video_quicktab-2,
        &#quicktabs-tab-basic_member_video_picker-2{
          background-image: url("../images/video_cat_icons/socialmedia.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.term-364,
        &#quicktabs-tab-cc_video_quicktab-3,
        &#quicktabs-tab-basic_member_video_picker-3{
          background-image: url("../images/video_cat_icons/netsafety.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.term-363,
        &#quicktabs-tab-cc_video_quicktab-4,
        &#quicktabs-tab-basic_member_video_picker-4{
          background-image: url("../images/video_cat_icons/money.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.term-362,
        &#quicktabs-tab-cc_video_quicktab-5,
        &#quicktabs-tab-basic_member_video_picker-5{
          background-image: url("../images/video_cat_icons/society.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.term-826,
        &#quicktabs-tab-cc_video_quicktab-6,
        &#quicktabs-tab-basic_member_video_picker-6{
          background-image: url("../images/video_cat_icons/studyskills.svg");
          background-size: 90%;
          background-position: center 80%;
          @media(min-width: $screen-desktop){
            background-position: center 60%;
          }
        }
        &.active{
          color: $orange;
        }
        &:hover{
          color: $orange;
          cursor: pointer;
        }
      }
    }
  }
  div.quicktabs_main{
    .quicktabs-tabpage{
      .views-row{
        .field_cc_video_image__card{
          border: 1px solid #e8e8e8;
        }
        h2.content__title{
          font-size: $font-size-base;
        }
      }
    }
  }
  // Video cards.
  .view__content .views-row {
    @media(min-width: $screen-desktop){
      min-height: 300px;
    }
  }
}

.view-rooster-video-library{
  .video__img_icon {
    margin: 0 auto;
    max-width: 85px;
    max-height: 85px;

    span.glyphicon-play {
      display: none;
    }
  }
}

.menu__hint{
  width: 100%;
  color: $orange;
  text-align: center;
}

.animated {
  opacity: 1;
}

.pre-animate {
  opacity: 0;
}

.view__content_title {
  h4 {
    padding-left: 1.25em;
    background-image: url('../images/orange_check_huge.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 22px;
  }
}

// Isotope grid.
.video__grid.container{
  padding-left: 0;
  padding-right: 0;
  // background: red;
  // min-height: 20vh;
  // Video cards.
  .views-row{
    text-align: center;
    min-height: 160px;
    @media(min-width: $screen-xs-min){
      min-height: 150px;
      // background: red;
    }
    @media(min-width: $screen-md-min){
      min-height: 150px;
      // background: green;
    }
    @media(min-width: $screen-lg-min){
      min-height: 150px;
    }
    .video__img_icon {
      position: relative;
    }
    .video-suggested {
      position: absolute;
      top: 2px;
      right: -20px;
      height: 25%;
      width: auto;
    }
    .field_cc_video_image__item{
      border: 1px solid #e8e8e8;
    }
    h2{
      font-size: $font-size-base;
      margin-top: 10px;

      @media(min-width: $screen-md-min){
        margin-top: 12px;
      }
      @media(min-width: $screen-lg-min){
        margin-top: 12px;
      }
    }
    &:hover{
      h2 a{
        color: $blue-dark;
      }
    }
  }
}

// Member Video Page.

// Member new video announcement.
.member__vid__announce{
  .member__vid__annouce__img{}
  .member__vid__annouce__label{}
  .member__vid__annouce__title{}
  .member__vid__annouce__date{}
  .member__vid__annouce__subtitle{}
  .member__vid__annouce__button{
    margin-top: 20px;
    a{
      @include btn-orange;
    }
  }
}


// Basic "My Videos" list.

.field_cc_my_videos{
  h2.pane__title{
    color: $blue-dark;
    font-family: $sofiapro;
    font-size: $font-size-large;
    font-weight: 600;
    padding-left: 15px;
    padding-right: 15px;
  }
  .field_cc_my_videos__item p,
  .field_cc_video_image__card{
    border: 1px solid #e8e8e8;
  }
  .field_cc_my_videos__item p{
    height: 81%;
    text-align: center;
    padding: 19% 74px 0;
  }
  h2.content__title{
    font-size: $font-size-base;
  }
}

.field_cc_video_image__modaal,
.field_cc_video_image__card,
.field_cc_video_image__custom_display,
.field_cc_video_image__series {
  border: 1px solid #e8e8e8;
  position: relative;
  .field_cc_video_image__watch_full {
    width: 30%;
    position: absolute;
    z-index: 100;
    right: 0;
  }
  .field_cc_video_image__locked {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.75);

    img {
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 65px;
      height: auto;
    }
  }
  .glyphicon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3vw;
    transform: translate(-50%, -50%);
    color: white;
    background: rgba(99, 97, 85, 0.8);
    width: 25%;
    height: 25%;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media(min-width: $screen-md-min){
      font-size: 2.5vw;
    }
    @media(min-width: $screen-lg-min){
      font-size: 1.75vw;
    }
  }

  &:hover {
    .glyphicon {
      background: rgba(119,117,105,0.8);
    }
  }
}

// The Featured Videos view.
.view__featured_videos {
  padding-bottom: 50px;

  .content__card--cc-video {
    .content__title {
      font-size: 1.25em;
    }
  }
}

//Video Library view
.view-rooster-video-library {

  .views-widget-filter-field_cc_video_topic_vocab_tid {
    float: none;

    .form-group {
      width: 100%;
    }

    .form-type-bef-link{
      display: inline;
    }

    label {
      padding-bottom: 10px;
      color: black;
      font-size: 21px;
    }

    a {
      display: inline-block;
      border: 2px solid #0d304b;
      border-radius: 8px;
      font-weight: 600;
      line-height: 10px;
      width: 110px;
      padding: 10px;
      text-align: center;
      color: #0d304b;
      font-family: $sofiapro;
      font-size: 13px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    a.active, a:hover {
      background-color: #0d304b;
      color: white;
    }
  }

  .views-widget-sort-by {
    label.control-label {
      float: left;
      margin-right: 10px;
      color: black;
      font-size: 16px;
    }

    .bef-select-as-links {
      float: left;
    }
    .form-item {
      display: inline;
      float: left;
    }

    a {
      font-weight: bold;
      margin-right: 15px;
    }
    a.active {
      text-decoration: underline;
    }
  }

  .views-exposed-widget {
    .btn {
      display: none;
    }
  }

  .video__grid::before, .video__grid::after {
    display: none;
  }

  .video__grid {
    display: grid;
    padding: 10px;

    @media(min-width: $screen-xs-min) {
      grid-template-columns: 1fr 1fr;
    }
    @media(min-width: $screen-sm-min) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media(min-width: $screen-md-min) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }


    .views-row {
      min-height: 0px !important;
      text-align: left;
      .views-field-title {
        padding: 0 5px;
      }
    }
  }

  .suggested-by-members {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
  }

}

//EZprox User
.role-EZProxy {
  .video__library__header__btns {
    .btn {
      display: none;
    }
  }
}
