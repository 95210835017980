// Membership Sidebar Pane
.membership_levels_membership_plans_pane{
  background-color: $well-bg;
  padding-bottom: 48px;
  text-align: center;
  .view__title{
    @include sidebar-heading;
    margin-bottom: 46px;
  }
  .view__subtitle{
    font-size: $font-size-small;
    margin: 16px 0;
  }
  
  .view__header{
    padding-top: 32px;
  }
  
  .view__content{
    .views-row{
      &:nth-child(3){
        .field_cc_plan_feature_image{
          &:before{
            content: 'Most Popular';
            display: block;
            position: absolute;
            top: -27px;
            background-color: $orange;
            color: $white;
            width: 100%;
            text-align: center;
            padding: 4px 0 2px;
          }
        }
      }
      margin-bottom: 36px;
      &:nth-child(odd){
        clear: left;
        @include breakpoint($screen-desktop){
          clear: none;
        }
      }
    }
    .field_cc_plan_feature_image{
      background-color: $white;
      padding-top: 14px;
      padding-bottom: 14px;
      position: relative;
      img{
        margin: 0 auto;
        max-width: 70px;
      }
    }
    .field_cc_plan_html_title{
      background-color: $white;
      color: $blue-dark;
      font-size: $font-size-large;
      font-weight: 600;
    }
    .taxonomy-term__content{
      background-color: $white;
      font-size: $font-size-small;
      margin-bottom: 16px;
      padding: 8px 8px 16px;
    }
    .taxonomy-term__footer{
      .field_cc_plan_starting_cost__label{
        font-size: $font-size-small;
      }
      .field_cc_plan_starting_cost__item{
        color: $orange;
        font-size: $font-size-h3;
        font-weight: 600;
      }
      .field_cc_plan_starting_cost__monthly {
        font-weight: 600;
        color: $blue-dark;
      }
    }
  }
}