// Search results.

.search-results-page{
  .page__title{
    font-size: 32px;
  }
}


// Site search api view.
.site_search {
  .view__filters{
    .views-exposed-form{
      width: 80%;
      margin: 2em 0;
    }
  }

  &__item {
    margin-bottom: 35px;
    border-bottom: 1px solid $gray-light;
    padding-bottom: 25px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__item_content {
    padding-left: 70px;
    min-height: 50px;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 50px 50px;
  }
  &__item--cc_blog {
    .site_search__item_content {
      background-image: url(/sites/all/themes/commoncraft/images/search_icons/search_icon_cc_blog.png);
    }
  }
  &__item--cc_video {
    .site_search__item_content {
      background-image: url(/sites/all/themes/commoncraft/images/search_icons/search_icon_cc_video.png);
    }
  }
}