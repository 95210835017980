// Testimonial field collections.

.testimonial--full {
  background: $white;
  color: $text-color;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
  text-align: left;

  .testimonial__copy{
    .testimonial__img{
      padding-bottom: 15px;
      @include breakpoint($screen-phone){
        padding-bottom: 0;
        max-width: 120px;
        float: left;
        padding-right: 15px;
      }
    }
    .testimonial__quote{
      font-family: $sofiapro;
      color: $orange;
      font-size: $font-size-h5;
    }
    .testimonial__customer{
      margin-top: 10px;
      font-size: $font-size-small;
      color: $gray-base;
      font-weight: bold;
      @include breakpoint($screen-desktop){
        padding-left: 120px;
      }
      .testimonial__name,
      .testimonial__profession {
        &:after{
          content:",";
        }
      }
    }
  }
}