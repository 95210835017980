// Global site footer styles.

.site__footer{
  padding-top: 40px;
  padding-bottom: 20px;
  background: $blue-dark;
  color: $white;
  a{
    color: $white;
    font-family: $sofiapro;
    font-weight: 600;
  }
  a.head-fb,
  a.head-tw{
    font-size: 30px;
    &:hover{
      text-decoration: none;
      color: $gray-light;
    }
  }
  ul{
    li{
      a{
        padding: 0px 10px 10px;
        color: $white;
        font-size: 20px;
        &:hover{
          color: $silver;
        }
      }
    }
  }
  .social_media_links {
    margin-bottom: 12px;
  }
  .global_copyright{
    padding-left: 10px;
    padding-right: 20px;
    font-family: $asap;
    font-style: italic;
    color: $gray-light;
  }
  .col__1{
    margin-bottom: 30px;
    padding-left: 24px;
  }
  .site__footer__footer{
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.footer_logo{
  img{
    width: 125px; 
  }
}