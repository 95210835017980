// Video Tools for the Video Node.

.video_tools {

  .popover {
    border-radius: 0;
    padding: 15px;
    text-align: center;

    .video_tools__join {
      margin-top: 10px;
      display: block;
    }
  }
}

.video_video_tools_full_access_me,
.video_video_tools_anonymous,
.video_video_tools_expired,
.video_video_tools_no_access_memb,
.pane__panels_mini__video_video_tools_semi_access_me {
  .pane__wistiaAPI_embed,
  .pane__wistiaAPI_download,
  .video_tools_anonymous {
    margin-top: 10px;
    margin-right: 5px;
    float: left;
  }
  .video_languages_panel_pane_2,
  .video_languages_panel_pane_1,
  .video_languages_panel_pane_4 {
    margin-top: 10px;
    margin-left: 5px;
    float: right;
  }
  .video_transcript_teaches {
    clear: both;
  }
}

.pane__cc_my_videos_videos {
  margin-top: 10px;
}

.video_tools__buttons--non_member {
  .popover-content {
    font-size: .88em;
  }
}

.field_lesson_plan_copy,
.pane__custom__video_tools_lesson_plan_copy_default {
  margin-top: 10px;
  text-align: center;
}

.field_lesson_plan_file {
  text-align: center;
  clear: both;
}

.language-list {
  .flag-icon {
    margin-left: 5px;
  }
  .lang-name {
    padding-left: 10px;
    line-height: 1em;
    vertical-align: bottom;
    color: black;
  }
  a:hover {
    .lang-name {
      color: $orange;
    }
  }
}

// New editable front page section
.field_bp_custom_top {
  iframe {
    width: 100% !important;
    border: 1px solid #e8e8e8;
  }
  .btn {
    margin-bottom: 40px;
  }
}
