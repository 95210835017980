// About Common Craft.
.pane__block__block_54{
  background-color: $silver-light;
  background-image: url(../images/decorative/L_S_Crop_Member_Blog.png);
  background-repeat: no-repeat;
  background-position: center 10px;
  background-size: 110px auto;
  padding-top: 94px;
  padding-bottom: 40px;
  text-align: center;
  
  .pane__title{
    @include sidebar-heading;
  }
  border-bottom: 2px solid $gray-light;
}