// Cut-out library

// A very ridiculous rule to make colorbox overlay have transparency in the iframe.
body,
html {
  background-color: transparent !important;
  background: transparent;
}

h1.pane__title,
h1.page__title{
  color: $orange;
  font-size: 56px;
  font-family: $sofiapro;
  font-weight: 600;
}
.field_subtitle__custom_display{
  font-size: $font-size-h4;
  line-height: $font-size-h3;
  font-weight: 300;
}

.cc_general_cc_general_cutout_nonajax_search{
  background: $silver-lighter;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 40px;

  &__title {
    margin-bottom: 0;
    font-size: 1.25em;
    @include breakpoint($screen-tablet){
      padding-right: 20%;
      padding-left: 20%;
    }
  }
  form {
    @include breakpoint($screen-tablet){
      padding-right: 20%;
      padding-left: 20%;
    }
  }  
}

.view__single_cut_out_landing {
  .view__header-title {
    margin-bottom: 0;
  }
  .view__footer{
    padding-top: 16px;
    padding-bottom: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $blue-dark;
    color: $orange;
    text-align: right;
  }
}

.view__single_cut_out_pack_page .views-row,
.view__single_cut_out .views-row{
  margin-top: 20px;
}

.pager--infinite-scroll{
  width: 100%;
} 

.views_infinite_scroll-ajax-loader{
  img{
    width: 30px;
  }
}

// Single cutout displayed in overlay
.node-type-single-cut-out{
  color: $blue-dark;
  .page__title{
    margin-bottom: 40px;
  }
  .pane__node_title{
    .pane__title{
      color: $blue;
      font-family: $sofiapro;
      font-weight: 600;
      font-size: $font-size-h5;
      @include breakpoint($screen-tablet){
        font-size: $font-size-h1;
        line-height: $headings-line-height;
      }
      margin-bottom: 2px;  
    }
    h1{
      font-family: $sofiapro;
      font-weight: 600;
      font-size: $font-size-h4;
      @include breakpoint($screen-tablet){
        font-size: $font-size-h1;
      }
    }
  }
  .diy__link{
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    font-family: $sofiapro;
  }
  .upsell{
    h2{
      font-size: 20px;
      font-weight: 600;
      @include breakpoint($screen-tablet){
        font-size: $font-size-h2;        
      }      
    }
    h4{
      font-size: 18px;
      @include breakpoint($screen-tablet){
       font-size: $font-size-h4;
      }            
    }
  }
  .field_cut_out_variation{
    margin-bottom: 20px;
  }
  .single-cut-out-overlay-col{
    .cut-out-button{
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
    }
  }
  // Auth poster
  .cut_out_poster_auth{
    img{
      width: 40%;
      margin: 20px auto;
    }
  }
  caption.cut_out__details{
    color: $blue;
    font-family: $sofiapro;
    font-weight: 600;
    font-size: $font-size-h4;
    line-height: 28px;    
  }
  table{
    margin-bottom: 100px;
  }
  th,
  .cut-out__feature{
    color: $blue;
    font-family: $sofiapro;
    font-weight: 600;
    font-size: $font-size-h4;
    line-height: 28px; 
    vertical-align: top;   
  }
  th span{
    padding: 10px 50px;
    display: block;
    font-size: $font-size-x-small;
    line-height: 12px;
    text-align: center;
    color: $white;
  }
  .cut-out-button {
    margin-top: 25px;
  }
}
.view__single_cut_out {
  .single-cut-out--pack {
    .content__title {
      display: none;
    }
  }
}
