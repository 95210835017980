// Member profile.

.page-profile{
  .pane__block__menu_menu_user_profile_menu{
    li a{
      @include btn-orange;
      margin-bottom: 2px;
    }
  }
  .pane__panels_mini__cc_user_profile{
    margin-top: 20px;
  }
  .field_cc_first_name__label,
  .field_cc_first_name__item,
  .field_cc_last_name__item,
  .field_cc_last_name__label{
    display: inline;
  }
  .main__content{
    @include breakpoint($screen-tablet){
      min-height: 70vh;
    }
  }
  .pane__block__block_15 {
    padding-bottom: 15px;
  }
  #mini-panel-cc_user_profile {
    .pane__token__mail {
      margin-bottom: 10px;
      .pane__title {
        float: left;
        padding-right: 10px;
      }
    }
    .pane__cc_user_billing_details .pane__content {
      div:nth-of-type(4){
        margin-bottom: 10px;
      }
    }
    #current-plan-auto-renew-status {
      margin-bottom: 10px;
    }
  }
  .pane__block__block_15 {
    margin-top:12px;
  }

   .btn-success {
    color: #333;
    background-color: #fff;
    border-color: #ccc; }

  .btn-success .icon {
    display: none; }

   .btn-success:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
}

#cc-profile-upgrade-form {
  padding-top: 75px;
  .upgrade-box {
    margin-bottom: 36px;
    text-align: center;
  }
  .upgrade-top {
    text-align: center;
    padding: 4px 0 2px;
  }
  .upgrade-box-current .upgrade-top {
    background-color: $orange;
    color: $white;
  }
  .upgrade-box-upgrade .upgrade-top {
    background-color: $blue-dark;
    color: $white;
  }
  .upgrade-box__info {
    background-color: $white;
    margin-bottom: 16px;
    padding: 8px 8px 16px;
  }
  .upgrade-box__image {
    max-width: 70px;
    margin: 0 auto;
    padding: 14px 0;
  }
  .upgrade-name {
    color: $blue-dark;
    font-size: $font-size-large;
    font-weight: 600;
  }
  .upgrade-plan-features {
    font-size: $font-size-small;
    margin-bottom: 16px;
    padding: 8px 8px 16px;
  }
  .upgrade-plan-display-price,
  .upgrade-current-status {
    font-size: $font-size-small;
    p {
      margin: 0;
    }
  }
  .upgrade-plan-price {
    color: $blue-dark;
    font-size: $font-size-large;
    font-weight: 600;
    margin-bottom: 15px;
  }
}
