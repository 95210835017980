// Webfonts.


//Asap
@font-face {
  font-family: 'Asap';
  src: url('../fonts/Asap/asap-regular-webfont.eot');
  src: url('../fonts/Asap/asap-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Asap/asap-regular-webfont.woff2') format('woff2'),
       url('../fonts/Asap/asap-regular-webfont.woff') format('woff'),
       url('../fonts/Asap/asap-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../fonts/Asap/asap-italic-webfont.eot');
  src: url('../fonts/Asap/asap-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Asap/asap-italic-webfont.woff2') format('woff2'),
       url('../fonts/Asap/asap-italic-webfont.woff') format('woff'),
       url('../fonts/Asap/asap-italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Asap';
  src: url('../fonts/Asap/asap-bold-webfont.eot');
  src: url('../fonts/Asap/asap-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Asap/asap-bold-webfont.woff2') format('woff2'),
       url('../fonts/Asap/asap-bold-webfont.woff') format('woff'),
       url('../fonts/Asap/asap-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('../fonts/Asap/asap-bolditalic-webfont.eot');
  src: url('../fonts/Asap/asap-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Asap/asap-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/Asap/asap-bolditalic-webfont.woff') format('woff'),
       url('../fonts/Asap/asap-bolditalic-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

// Crete Round
@font-face {
  font-family: 'Crete Round';
  src: url('../fonts/Crete_Round/creteround-regular-webfont.eot');
  src: url('../fonts/Crete_Round/creteround-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Crete_Round/creteround-regular-webfont.woff2') format('woff2'),
       url('../fonts/Crete_Round/creteround-regular-webfont.woff') format('woff'),
       url('../fonts/Crete_Round/creteround-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Crete Round';
  src: url('../fonts/Crete_Round/creteround-italic-webfont.eot');
  src: url('../fonts/Crete_Round/creteround-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Crete_Round/creteround-italic-webfont.woff2') format('woff2'),
       url('../fonts/Crete_Round/creteround-italic-webfont.woff') format('woff'),
       url('../fonts/Crete_Round/creteround-italic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

// Gotham Rounded
@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-Light.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-Light.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-LightItalic.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-LightItalic.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-Book.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-Book.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-BookItalic.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-BookItalic.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-BookItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-Medium.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-Medium.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-MediumItalic.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-MediumItalic.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-Bold.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-Bold.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/Gotham_Rounded/GothamRounded-BoldItalic.eot');
  src: url('../fonts/Gotham_Rounded/GothamRounded-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham_Rounded/GothamRounded-BoldItalic.woff') format('woff'),
    url('../fonts/Gotham_Rounded/GothamRounded-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

// Sofia Pro
@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofia_pro/sofia_pro_light.eot');
  src: url('../fonts/sofia_pro/sofia_pro_light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/sofia_pro/sofia_pro_light.woff') format('woff'),
    url('../fonts/sofia_pro/sofia_pro_light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofia_pro/sofia_pro_semi_bold.eot');
  src: url('../fonts/sofia_pro/sofia_pro_semi_bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/sofia_pro/sofia_pro_semi_bold.woff') format('woff'),
    url('../fonts/sofia_pro/sofia_pro_semi_bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}