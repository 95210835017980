// Subscribe Block.
.pane__block__block_55{
  background-color: $silver-light;
  background-image: url(../images/envelope-rocket.png);
  background-position: center 60px;
  background-repeat: no-repeat;
  background-size: 106px auto;
  padding-top: 180px;
  padding-bottom: 70px;
  text-align: center;
  
  .pane__title{
    @include sidebar-heading;
  }
  
  .sidebar-rss{
    .subscribe__rss__icon{
      display: block;
      img{
        width: 24px;
        height: auto;
      }
    }
  }
}

.subscribe__rss__mailchimp{
  
  form{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mailchimp_form__email{
    margin-right: 6px;
  }
  
  input.email{
    @include text-input;
    width: 180px;
  }
  
  .mailchimp_form__email{
    font-size: 12px;
  }
  
  .email_sub{
    @include btn-orange;
  }
}