// User login and password recover pages.

.user__anon__page{
  #user-login,
  #cc-login-user-pass{
    margin-bottom: 3em;
    @include breakpoint($screen-tablet){
      width: 40%;
    }
    button{
      @include btn-orange();
    }
  }
}

// EzProxy Role - this hids an extra no access section that was showing up.
.role-EZProxy {
  #mini-panel-video_video_tools_no_access_memb {
    display: none;
  }
}
