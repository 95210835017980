// Join page.

.page-join, .page-full-access{
  h1.page__title{
    // margin-top: 100px;
    color: $orange;
    font-size: 56px;
    font-family: $sofiapro;
  }
  .page__subtitle{
    color: $gray-dark;
    font-size: $font-size-large;
    font-weight: 600;
  }
  .join__paytitle{
    padding-top: 30px;
    text-align: center;
    img{
      max-width: 150px;
    }
    a{
      // text-decoration: underline;
      border-bottom: 1px solid $orange;
    }
  }
  .make-selection{
    margin-top: 20px;
    margin-bottom: 10px;
    @include breakpoint($screen-md){
      margin-top: 50px;
      margin-bottom: 20px;
    }
    color: $orange;
    font-size: 22px;
    font-weight: 600;
    font-family: $sofiapro;
  }
  .row-explanation-title h2{
    color: $blue-dark;
    font-size: $font-size-h4;
    line-height: $font-size-h3;
    font-weight: 600;
    font-family: $sofiapro;
  }
  .checkout-item-types,
  .checkout-item-counts{
    .panel-body{
      cursor: pointer;
    }
    input.checkout-item__input{
      display: inline-block;
      max-width: 7%;
      float: left;
    }
    .plan-type-description,
    .plan-count-description{
      max-width: 85%;
      margin-left: 4%;
      display: inline-block;
      float: left;
      color: $blue-dark;
      font-size: 18px;
      line-height: 22px;
      cursor: pointer;
      p,
      span{
        color: $orange;
        font-size: $font-size-h4;
        line-height: $font-size-h3;
        font-weight: 600;
        font-family: $sofiapro;
      }
    }
    .plan-count-description{
      p{
        margin-top: 8px;
      }
    }
    .row-explanation-contact{
      margin-top: 10px;
    }
  }
  .checkout-item-name{
    color: $blue-dark;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    font-family: $sofiapro;
  }
  .checkout-item-levels{
    .checkout-item.panel{
      border: 0;
      cursor: pointer;
      &:nth-child(3){
        .checkout-item-image{
          &:before{
            content: 'Most Popular';
            display: block;
            position: absolute;
            top: -12px;
            left: 0;
            background-color: $orange;
            color: $white;
            width: 100%;
            text-align: center;
            padding: 4px 0 2px;
          }
        }
      }
    }
  }
  .checkout-row-disabled {
    opacity: .4;
    .plan-type-description,
    .plan-count-description,
    .panel-body,
    .checkout-item.panel{
      cursor: default;
    }
  }
}

// Item types.
.checkout-item-types,
.checkout-item-counts{
  .row-explanation,
  .checkout-item{
    background: $well-bg;
    border: 0;
    border-top: 1px solid $gray-light;
    border-radius: 0;
    margin-bottom: 0;
  }
  .row-explanation{
    border-top: 0;
    padding: 1px 1px 1px 70px;
  }
}
.checkout-item-counts{
  margin-top: 20px;
  @include breakpoint($screen-md){
    margin-top: 0;
  }
}
// See also _panel--plans-pricing.scss
.user_register_form__plan_levels{
  background: $well-bg;
  margin-top: 20px;
  .row-explanation-title h2{
    color: $blue-dark;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    font-family: $sofiapro;
    margin-bottom: 20px;
  }
  .panel{
    background: none;
    box-shadow: none;
    border: 0;
    .panel-body{
      padding-right: 0;
      padding-left: 0;
      text-align: center;
      position: relative;
      .field_cc_plan_feature_image,
      .field_cc_plan_html_title,
      .taxonomy-term__content{
        background: $white;
        margin-bottom: 0;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 10px;
        text-align: center;
        ul{
          margin-bottom: 0;
        }
      }
      .checkout-plan-price{
        margin-top: 20px;
      }
      .checkout-item-features p{
        margin-bottom: 8px;
      }
    }
    .field_cc_plan_feature_image img{
      margin-top: 20px;
      margin-bottom: 10px;
      width: 60%;
      max-width: 70px;
    }
    button{
      margin-top: 20px;
    }
    .checkout-plan-price{
      color: $blue-dark;
      font-size: $font-size-large;
      font-weight: 600;
    }
  }
  button[value=SELECTED] {
    background-color: $blue-dark;
    border: $blue-dark 1px solid;
  }
}

// Registration form fields
.user_register_form__registration_fields{
  @include breakpoint($screen-phone){
    .container{
      padding-left: 10%;
      padding-right: 10%;
    }
  }
  @include breakpoint($screen-tablet){
    .container{
      padding-left: 14%;
      padding-right: 14%;
    }
  }
  @include breakpoint($screen-lg-desktop){
    .container{
      max-width: 900px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .control-label{
    margin-bottom: 2px;
  }
  .panel-default > .panel-heading,
  fieldset,
  legend{
    background: none;
    border: 0;
  }
  .join-sec-num,
  .join-form-section-header{
    color: $blue-dark;
    font-size: $font-size-h4;
    line-height: $font-size-h3;
    font-weight: 600;
    font-family: $sofiapro;
    strong{
      font-style: italic;
    }
  }
  .join-sec-num{
    text-align: center;
  }
  .password-strength{
    margin-bottom: 10px;
    @include breakpoint($screen-sm){
      width: 30%;
      .password-strength-text{
        display: inline-block;
        font-size: $font-size-x-small;
        line-height: $font-size-small;
        font-weight: 600;
        font-family: $sofiapro;
        margin-top: 2px;
        color: $orange;
      }
      .password-strength-title{
        display: inline-block;
        float: left;
        font-size: $font-size-x-small;
        line-height: $font-size-small;
        font-weight: 600;
        font-family: $sofiapro;
        margin-top: 4px;
        margin-right: 4px;
      }
      .password-indicator{
        margin-bottom: 10px;
        background: $well-bg;
        height: 10px;
        .indicator{
          background: $gray-light;
          height: 10px;
        }
      }
    }
  }
  .password-confirm{
    margin-bottom: 10px;
    display: inline-block;
    font-size: $font-size-x-small;
    line-height: $font-size-small;
    font-weight: 600;
    font-family: $sofiapro;
    margin-top: 4px;
    margin-right: 4px;
    .ok{
      color: $green;
    }
  }
  .form-item-registration-fields-field-cc-billing-info-credit-card-number{
    margin-top: 10px;
    background: url('/sites/all/themes/commoncraft/images/credit_cards.png') right 30px no-repeat;
    background-size: 18%;
    input{
      width: 80%;
    }
  }
  .form-item-registration-fields-field-cc-billing-info-credit-card-code{
    background: url('/sites/all/themes/commoncraft/images/cvv.png') right 20px no-repeat;
    padding-right: 30px;
    background-size: 19%;
    @include breakpoint($screen-desktop){
      background-position: right 27px;
      background-size: 40%;
    }
    input{
      width: 70%;
    }
  }
  .form-user_register_submit{
    width: 100%;
    @include breakpoint($screen-tablet){
      width: 200px;
    }
    margin-top: 20px;
    font-size: $font-size-h3;
  }
  .field-name-field-cc-billing-info{
    overflow: hidden;
    padding: 10px;
    border: 1px solid #b1b3b6;
    @include breakpoint($screen-tablet){
      padding: 20px;
    }
    h2.join-form-section-header{
      text-align: center;
    }
    .form-item-registration-fields-field-cc-billing-info-credit-card-code,
    .form-item-registration-fields-field-cc-billing-info-credit-card-exp-year,
    .form-item-registration-fields-field-cc-billing-info-credit-card-exp-month{
      label{}
      @include breakpoint($screen-desktop){
        width: 26%;
        float: left;
        margin-right: 20px;
      }
    }
  }
  #bullet-text p{
    font-family: $asap;
    font-size: $font-size-small;
    line-height: 16px;
    font-style: italic;
    margin-bottom: 20px;
  }
  #total{
    padding-top: 20px;
    padding-bottom: 10px;
    p{
      color: $blue-dark;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      font-family: $sofiapro;
    }
  }
}


