//Book Promo.
.sidebar_book_promo{
  background-color: $silver-light;
  
  .pane__content{
    padding: 60px 0;
    text-align: center;
  }
  
  .sidebar_book_promo__book_icon{
    margin-bottom: 20px;
    
    a{
      img{
        width: 90px;
      }
    }
  }
  
  .sidebar_book_promo__title{
    @include sidebar-heading;
    margin-bottom: 20px;
  }
  
  .sidebar_book_promo__book_title{
    margin-bottom: 5px;
    
    a{
      color: $blue-dark;
      font-weight: 600;
    }
  }
  
  .sidebar_book_promo__book_tag{
    margin-bottom: 20px;
  }
}