// Video Series nodes and fields.
.field_paragraph_video_series {
  margin-bottom: 1em;
}

.main__content .video_series {
  padding-top: 50px;

  &__title {
    margin-top: 0;
    color: $orange;
    text-align: center;
  }
}

.node-type-video-series {
  padding-bottom: 0;
  
  .page__title {
    margin-bottom: 50px;
  }

  .field-collection-container {
    border-bottom: none;
  }
}

.content__series--cc-video {
  padding-bottom: 21px;

  .content__title {
    color: $blue;
  }

  .cc-video__image_captioned_title {
    margin: 0;
    padding: 3px 0 2px;
    text-align: center;
    background-color: $orange;
    color: white;
    font-size: 1em;
  }

  .cc-video__caption-toggle {
    display: block;
    padding-top: 5px;
    padding-left: 3px;
    color: $gray-dark;
    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }

  .video_tools {
    @media (min-width: 768px) {
      .col-sm-6:nth-child(2) {
        padding-right: 5px;
      }
      .col-sm-6:last-child {
        padding-left: 5px;
      }
    }
  }

  .video_tools--member a,
  .video_tools__lessonplan,
  .video_tools__embed,
  .video_tools__download {
    width: 100%;
  }
}
.content__promo--video-series {
  padding-bottom: 21px;
  background-color: white;
  margin-bottom: 1em;

  .content__title {
    margin-bottom: 21px;
    color: $blue;
  }

  .field_promo_text {
    margin-bottom: 21px;
  }
  .field_promo_images__promo {
    margin-top: 21px;
  }
}

.video_series_group {
  padding-top: 25px;
  padding-bottom: 25px;

  .sub-title {
    color: $orange;
    margin-top: 0;
  }
  .sub-title--suffix {
    color: $blue;
    font-weight: normal;
    font-size: .8em;
    margin-left: 5px;
    &:hover {
      cursor: pointer;
      color: $gray-dark;
    }
  }
}

.video_series_landing_page_list_panel_video_series_landing {
  margin-top: 1em;
}

.video_series--teaser {
  text-align: center;

  .video_series__title {
    line-height: .75em;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .video_series__title a {
    color: $blue;
    font-size: 20px;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
      color: $gray-dark;
    }
  }

  .video_series__subtitle {
    margin-top: 0;
    font-size: 14px;
  }
}

.pane__custom__video_series_join_button {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width:768px) and (max-width:991px) {
  .video_tools__lessonplan {
    font-size: 10px;
    .glyphicon {
      margin-right: 0;
    }
  }
  .video_tools__download {
    font-size: 15px;
    .glyphicon {
      margin-right: 0;
    }
  }
}
@media (min-width:992px) and (max-width:1199px) {
  .video_tools__lessonplan {
    .glyphicon {
      margin-right: 0;
    }
  }
}
