.node-type-partner{
  .main__title{
    h2.pane__title{
      font-size: $font-size-small;
      font-weight: 600;
      margin-bottom: 8px;
    }
    h1.page__title{
      font-size: $font-size-large;
      margin-top: 0;
      @include breakpoint($screen-desktop){
        font-size: $font-size-x-large;
      }
    }
  }
  
  .main__content{
    h3{
      font-size: $font-size-large;
      font-weight: 600;
      @include breakpoint($screen-desktop){
        margin-top: 0;
      }
    }
    
    .content_row__item{
      border-bottom: 1px solid #ebebeb;
    }
    
    .content_row__item:first-child{
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 32px;
    }
    
    .content_row__item:nth-child(2){
      display: flex;
      flex-direction: column-reverse;
      padding-top: 48px;
      padding-bottom: 32px;
      text-align: center;
      
      @include breakpoint($screen-desktop){
        display: block;
        
        .col-md-6:first-child{
          .field_prgh_text_editor{
            padding: 0 15%;
          }
        }
      }
      
      h2{
        color: $orange;
        font-weight: 600;
      }
    }
  }
  
  .main__bottom{
    .pane__entity_field__field_partner_content_bottom_row{
      h2{
        color: $blue-dark;
        font-weight: 600;
      }
      .cta__content__item{
        margin-bottom: 48px;
      }
      h3.field_fc_title{
        font-size: $font-size-large;
        font-weight: 600;
      }
    }
  }
}