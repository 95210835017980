// Access Denied

#sliding-popup {
  width: 100%;
  background-color: $blue-dark;
  color: white;
}

.cookie_consent {
  margin-top: 15px;
  &__message {
    text-align: left;
  }
}
.eu-cookie-withdraw-wrapper {
  display: none !important;
}