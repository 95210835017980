// Alerts

.pane__alert {
  margin: 0;
  padding: 5px 5px 0 5px;
  border-radius: 0;

  &.alert-dismissible .close {
    right: 0;
  }
}

.alert--primary {
  background-color: $blue-dark;
  color: $white;
}

.alert--orange {
  background-color: $orange-mute;
  color: $white;

  a {
    color: $blue-dark;

    &:hover {
      color: $gray-dark;
    }
  }
}