// Cut-out landing page main content

.video_cutout_landing__button p{
  text-align: center;
  font-size: $font-size-small;  
}

.view__cut_out_category_landing_listing__panel_pane_1{
  @media(max-width: $screen-desktop){
    .list-unstyled{
      column-count: 2;
    }
  }
}

.pane__views_panes__cut_out_category_landing_listing_panel_pane_1{
  .pane__title{
    font-size: $font-size-base;
    font-weight: 300;
    @media(min-width: $screen-desktop){
      font-size: $font-size-large;
    }
  }
}

.cut_out_category_landing_listing_panel_pane_1{
  .pane__title{
    font-size: $font-size-base;
    font-weight: 300;
  }
}

.view__cut_out_category_landing_listing .field_footer_text__landing{
  background-color: $blue-dark;
  color: #fefeff;
  p{
    line-height: 2.2em;
  }
}

// A very ridiculous rule to make colorbox overlay have transparency in the iframe.
body,
html {
  background-color: transparent !important;
  background: transparent;
}
