// Global site landing page styles.

.cut_out_category_landing_listing_panel_pane_1,
.video_topics_landing_list_panel_pane_1{
  .pane__title{
    color: $gray-dark;
    background: $silver;
    text-align: center;
    padding: 10px 0;
    margin: 10px 0;
    h2{
      font-size: $font-size-large;
      margin-top: 12px;
      @include breakpoint($screen-desktop){
        font-size: $font-size-x-large;
        margin-top: 16px;
      }
    }

  }
}
// Marketing pages and cutout library.
.cut_out_library_top_info,
.pane__panels_mini__page_video_cutout_landing{
  @include breakpoint($screen-desktop){
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .pane__panels_mini__content--2{
    @include breakpoint($screen-md-min) {
      //padding-left: 15%;
    }
  }
}

.term__landing{
  .term__wrapper{
    @include breakpoint($screen-desktop){
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .term__header{
    .term__link{
      font-weight: 600;
    }
  }
  .term__title,
  .term__content__list_item{
    color: $blue-dark;
    @extend .small-heading;
  }
  .term__title a{
    color: $blue-dark;
    &:hover{
      color: $orange;
    }    
  }
  .term__count{
    color: $gray-dark;
    font-family: $gothamrounded;
    font-weight: 300;
  }
  .field_footer_text__landing{
    text-align: center;
    font-family: $sofiapro;
    padding: 20px 0;
    margin: 0px 0;    
  }
}

.term__content{
  &__label{
    font-size: $font-size-small;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .term__title,
  .content__title a,
  .term__content__list_item{
    font-size: 20px;
    line-height: 24px;
    margin: 4px 0;
    span{
    }
  }
  h2.content__title{
    margin-top: 4px;
    margin-bottom: 22px;
  }
}
