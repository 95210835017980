// Global site header styles.

#mini-panel-global_header{
  padding-top: 10px;
}

.navbar-brand{
  height: auto;
  padding: 0;
  width: 140px;
}

.page__join {
  .navbar-brand{
    display: block;
    float: none;
    margin: 0 auto 50px;
  }
}

// Search form
.search-form__wrapper{
  .search-form__form{
    margin-top: 5px;
  }
}

.search-form__toggle{
  display: none;
  @include breakpoint($screen-tablet){
    display: block;
  }
  margin: 10px 0px 10px;
  button{
    font-size: 16px;
    border: 0;
    color: $gray-light;
    &:hover,
    &:focus{
      background: none;
    }
  }
}

.search-form__form{
  display: none;
  @include breakpoint($screen-tablet){
    display: block;
  }
  width: 300px;
  margin-top: 2px;
  form{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    .input-group{
      width: 100%;
      button,
      input{
        background: $silver;
        line-height: 20px;
        border: 0;
        color: $gray-light;
        font-family: $sofiapro;
        font-size: 16px;
        font-weight: 600;
      }
      .input-group-btn{
        width: 40px;
      }
      button{
        font-size: 16px;
        line-height: 21px;
        display: block;
      }
      input{
        font-size: 12px;
        // background-color:transparent;
        box-shadow: inset 0px 0px 0px 0px white;
      }
    }
  }
}

// Navbar Toggle.
.navbar-toggle{
  border: none;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
  background-color: $focus_gray;
}

.navbar-default .navbar-toggle .icon-bar{
  background-color: $header_gray;
  height: 4px;
  width: 30px;
}

.navbar-toggle .icon-bar + .icon-bar{
  margin-top: 5;
}

// Navbar
.navbar-nav{
  padding-top: 40px;
  @include breakpoint($screen-tablet){
    padding-top: 0;
  }
  margin-left: 8px;
  margin-top: 6px;
  li a{
    font-family: $sofiapro;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
  }
}

// Sign in
.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:hover, 
.navbar-default .navbar-nav > .open > a:focus,
.navbar-nav > li.open a.dropdown-toggle,
.navbar-nav > li.dropdown a{
  background: none;
  @include breakpoint($screen-tablet){
    background: $silver;
    border-radius: 6px;
  }
  color: $blue-dark;
  font-family: $sofiapro;
  font-weight: 600;
  &:focus,
  &:hover{
    @include breakpoint($screen-tablet){
      background: $silver;
    }
  }
}

.navbar-nav > li > .dropdown-menu{
  padding: 22px 20px;
  width: 260px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  @include breakpoint($screen-tablet){
    background: $silver;
    border: 1px solid $silver;
    border-radius: 4px;
  }
  top: 110%;
  input{
    font-family: $sofiapro;
    font-weight: 600;
  }
  .form-actions{
    .form-submit{
      background: $blue-dark;
      color: $white;
      border: 0;
      font-family: $sofiapro;
      font-weight: 600;
    }
    a{
      background: $orange;
      color: $white;
      border: 0;
      font-family: $sofiapro;
      font-weight: 600;
    }
  }
}
.user-recover a{
  color: $gray-light;
}

// Primary nav.

nav.navbar-default .navbar-nav > .active > a, 
nav.navbar-default .navbar-nav > .active > a:hover, 
nav.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}

.navbar-collapse{
  //background: $gray-light;
  @include breakpoint($screen-tablet){
    background: none;
  }
  .menu__search{
    @include breakpoint($screen-tablet){
      display: none;
    }
  }
}
