// Global helper classes.

// CC Colors

.orange-bg{
  background: $orange;
}

.orange-mute{
  background: $orange-mute;
}

.blue {
  // background: $blue;
}

.blue-dark{
  background: $blue-dark;
}

.gray-light{
  background: $gray-light;
}

.gray-med{
  background: $gray-med;
}

.gray-dark{
  background: $gray-dark;
}

.green{
  background: $green;
}

.white{
  background: $white;
}

.background__orange {
  background-color: $orange;
}
.background__blue {
  background-color: $blue-dark;
}
.background__gray {
  background-color: $gray-light;
}
.background__silver_light {
  background-color: $silver-light;
}
.background__silver_lighter {
  background-color: $silver-lighter;
}
.background__green {
  background-color: $green;
}

// Font classes

.asap{
  font-family: $asap;
}

.asapbold{
  font-family: $asap;
  font-weight: 700;
}

.asapbolditalic{
  font-family: $asap;
  font-style: italic;
  font-weight: 700;
}

.asapitalic{
  font-family: $asap;
  font-style: italic;
}

.creteround{
  font-family: $creteround;
  font-weight: 400;
}

.creterounditalic{
  font-family: $creteround;
  font-style: italic;
  font-weight: 400;
}

.gothamroundedlight{
  font-family: $gothamrounded;
  font-weight: 300;
}

.gothamroundedlightitalic{
  font-family: $gothamrounded;
  font-style: italic;
  font-weight: 300;
}

.gothamroundedbook{
  font-family: $gothamrounded;
  font-weight: 400;
}

.gothamroundedbookitalic{
  font-family: $gothamrounded;
  font-style: italic;
  font-weight: 400;
}

.gothamroundedmedium{
  font-family: $gothamrounded;
  font-weight: 500;
}

.gothamroundedmediumitalic{
  font-family: $gothamrounded;
  font-style: italic;
  font-weight: 500;
}

.gothamroundedbold{
  font-family: $gothamrounded;
  font-weight: 700;
}

.gothamroundedbolditalic{
  font-family: $gothamrounded;
  font-style: italic;
  font-weight: 700;
}

.sofiaprolight{
  font-family: $sofiapro;
  font-weight: 300;
}

.sofiaprosemibold{
  font-family: $sofiapro;
  font-weight: 600;
}

.text__orange {
  color: $orange;
}

.text__black {
  color: black;
}

.link__white {
  color: white;
  &:hover {
    color: $gray-lighter;
  }
}

// Images
img{
  width: 100%;
}

.hide-me{
  display: none;
}

.pager--infinite-scroll{
  width: 50px;
  text-align: center;
}

.video__embed{
  border: 1px solid #e8e8e8;
}

// Link colors
// Default non classed links are orange with white hover.
.link-green {
  color: $green;
}
.link-green-dark {
  color: $green-dark;
}
.link-blue {
  color: $blue;
}
.link-blue-dark {
  color: $blue-dark;
}
// Helping boostrap pill nav component work w/Drupal output.

// Pills
// -------------------------
.nav-pills {
  margin-top: 40px;
  margin-bottom: 40px;
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: $nav-pills-border-radius;
    }
    + li {
      margin-left: 2px;
    }

    // Active state
    a.active {
      &,
      &:hover,
      &:focus {
        color: $nav-pills-active-link-hover-color;
        background-color: $nav-pills-active-link-hover-bg;
      }
    }
  }
}


// Stacked pills
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}

span.file img{
  max-width: 20px;
}
