// Soccer Guide page manager page

.pane__cc_json_consumer {
  border-top: 1px solid #ddd;

  .guide_body__item-image{
    img{
      width: 100% !important;
      height: auto !important;
    }
  }
  .guide_header__copy {
    img {
      width: 100% !important;
      height: auto !important;
      max-width: 520px;
    }
  }
  .guide_header__title {
    color: $orange;
    font-size: 44px;
    font-family: $sofiapro;
    font-weight: 600;
  }

  .guide_header h3 {
    font-size: $font-size-h4;
    line-height: $font-size-h3;
    font-weight: 300;
    padding-bottom: 50px;
  }

  .guide_header__sharing_title {
    font-size: $font-size-h4;
    line-height: $font-size-h3;
    font-weight: 300;
  }

  .guide_header__service-links {
    height: 40px;
    width: 50%;
    padding: 9px;
    margin-bottom: 30px;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    li {
      display: block;
      float: left;
      margin-right: 15px;
    }
    img {
      height: 24px;
      width: auto;
    }
  }

  #guide-accordion {
    .panel-heading {
        //color: #888a8d;
        color: #292c3b;
        background-color: #f5f5f5;
        border: 1px solid #292c3b;

      &.collapsed:before{
        content:"\A";
        border-style: solid;
        border-width: 6px 11px 6px 0;
        border-color: transparent #ee7729 transparent transparent;
        position: absolute;
        transform: rotate(180deg);
        margin-top: 3px;
      }

      &:hover {
        cursor: pointer;
        border: 1px solid $orange;
      }

      h4 {
        margin-left: 22px;
      }
    }

    .panel-heading[aria-expanded="true"]{
      border: 1px solid $orange;
    }

    .panel-heading[aria-expanded="true"]:before{
      content:"\A";
      border-style: solid;
      border-width: 6px 11px 6px 0;
      border-color: transparent #ee7729 transparent transparent;
      position: absolute;
      transform: rotate(-90deg);
      margin-top: 3px;
    }

    .guide_body__permalink {
      text-align: right;
      font-size: $font-size-small;
    }

    h3 {
      font-size: 26px;
    }
  }
}
