// Video node page

// Add pointer.
.video_transcript_teaches h2.pane__title{
	cursor: pointer;
}

.video_transcript_teaches_lg h2.pane__title{
  padding-left: 15px;
}

.node-type-cc-video{
  .main__title{
    margin-bottom: 1em;
    h2.pane__title{
      font-size: $font-size-large;
      font-weight: 600;
      margin-bottom: 0;
    }
    h1.page__title{
      font-size: $font-size-h3;
      line-height: $line-height-base;
      margin-top: 0;
      margin-bottom: 0;
      @include breakpoint($screen-tablet) {
        font-size: $font-size-h1;
      }
    }
    h3.video__subtitle{
      font-size: $font-size-base;
      margin: 0;
    }
  }

  .field_cc_title_suffix__item {
    font-style: italic;
    margin-bottom: 1em;
  }


  .video__side__cta,
  .video__side__cta h3,
  .video__plan__empty,
  .video__plan__empty h3,
  .field_lesson_plan_copy,
  .pane__custom__video_tools_lesson_plan_copy_default {
    font-size: 20px;
    @include breakpoint($screen-desktop){
      margin-top: 0;
    }
  }
  .field_lesson_plan_image__custom_display{
    @include breakpoint($screen-desktop){
      width: 60%;
      margin: 0 auto;
    }
  }
  .field_cc_video_image__custom_display:hover {
    cursor: pointer;
  }
  .cc_video_language_sharing_meta{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .field_cc_public_embed {
    border: 0px;
    .cc_wistia_overlay {
      padding: 0 75px;
      font-family: "Sofia Pro", sans-serif;
      font-size: 22px;
      line-height: 26px;
      color: white;

      a {
        font-size: 32px;
        color: #ee7729;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
    .field_cc_video_image__locked {
      position: absolute;
      right: 5px;
      bottom: 35px;
      width: 85px;
      height: auto;
      z-index: 100;
    }
  }
  .field_cc_public_embed__watch_full {
    width: 25%;
    position: absolute;
    z-index: 100;
    right: 0;
  }
  .embed-responsive {
    border: 1px solid #e8e8e8;
  }
  .cc_member_video_tools{
    margin: 20px 0;
  }
  .pane__wistiaAPI_embed,
  .pane__wistiaAPI_download,
  .video__captions_show,
  .video__captions_hide{
    display: inline-block;
  }
  .pane__panels_mini__video_transcript_teaches{
    .pane__title{
      color: $orange;
      font-size: $font-size-base;
      font-weight: 600;
      display: inline-block;
    }
  }
  .pane__custom__video_node_sidebar_cta{
    .pane__title{
      color: $orange;
      font-size: $font-size-large;
      font-weight: 600;
    }
    h5{
      font-weight: 600;
    }
  }
  .pane__entity_field__field_cc_video_related_videos{
    padding-bottom: 2em;
    border-bottom: 1px solid #ebebeb;
  }
  .membership_levels_membership_plans_pane{
    background-color: white;
  }
  // Tweaking embedded email signup.
  .sidebar__content{
    .ck_naked{
      margin-top: 0;
      .ck_form_fields{
        padding-top: 0;
      }
    }
  }
}
