// Layout.


body{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content{
  flex: 1 0 auto;
}

//ie 11 fix
.row {
  width: 100%;
}

// Responsive tables

table {
  // border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  color: $white;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  table {
    // border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    // border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    // border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  table td {
    // border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  table td:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  table td:last-child {
    // border-bottom: 0;
  }
}

th, td{
/*   background: blue; */
  text-align: center;
}

.pane__panels_mini__2col{
  .pane__panels_mini__content{
    margin-bottom: 20px;
    @include breakpoint($screen-desktop){
      margin-bottom: 0;
    }
  }
}
