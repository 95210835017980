.promo_cut_outs{
  background-color: $blue-dark;
  background-image: url(../images/cutouts-background-desktop_2.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  color: #FFF;
  padding-top: 60px;
  padding-bottom: 200px;
  text-align: center;
  @include breakpoint($screen-desktop){
    background-image: url(../images/cutouts-background-desktop_2.png);
    min-height: 640px;
    padding-top: 40px;
    text-align: left;
    .pane__content{
      font-size: $font-size-large;
      // max-width: 30%;
    }
    h2.pane__title{
    }
  }
  .promo_cut-out_quote{
    margin-top: 60px;
    @include breakpoint($screen-desktop){
      margin-top: 40px;
    }
    text-align: center;
    .cut-out_quote{
      margin-bottom: 20px;
      font-family: $sofiapro;
      font-style: italic;
      font-size: $font-size-h4;
    }
    .cut-out_quote_name{
      color: $white;
      font-size: $font-size-base;
    }
  }
  h2.pane__title{
    font-weight: 600;
  }
  
  .btn{
    font-weight: 600;
    margin-right: 10px;
  }
}