// Promoted Blog Posts.

.promoted_blog_posts_panel_pane_1{
  background-color: $wh_blue;
  color: $wh_white;
  padding: 30px 0;
  text-align: center;

  .pane__title{
    font-size: $font-size-large;
    margin-bottom: 16px;
  }

  ul{
    font-weight: 600;
    list-style: none;
    padding: 0;
  }

  li{
    margin-bottom: 16px;
  }

  a{
    color: $wh_white;
  }
}