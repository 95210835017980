// Video Landing page main content

.term__content__list_item{
  a{
    color: $blue-dark;
    &:hover{
      color: $orange;
    }
  }
}

.view__video_topics_landing_list .field_footer_text__landing{
  background-color: $blue-dark;
  color: #fefeff;
  p{
    line-height: 2.2em;
  }
}

@include breakpoint($screen-desktop){
  .pane__views_panes__video_topics_landing_list_panel_pane_1 {
    margin-bottom: 40px;
  }
}
