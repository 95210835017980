// Page header animation.

.pane__panels_mini{
  .animated {
    .pane__content {
      position: absolute;
      width: 80%;
      left: 18%;
      //margin-left: -225px;
      height: 40px;
      //top: 50%;
      margin-top: -20px;
      //margin: 20px auto;
      //text-align: center;
    }
    h1.animated--headline {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      color: #ee7729;
      font-family: "Sofia Pro", sans-serif;
      font-size: 56px;
      font-weight: 600;
    }
    .word {
      position: absolute;
      width: 360px;
      margin-left: 1.2rem;
      opacity: 0;
      background-color: transparent;
    }
    .letter {
      display: inline-block;
      position: relative;
      float: left;
      transform: translateZ(25px);
      transform-origin: 50% 50% 25px;
    }
    .letter.out {
      transform: rotateX(90deg);
      transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    .letter.behind {
      transform: rotateX(-90deg);
    }
    .letter.in {
      transform: rotateX(0deg);
      transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    .wisteria {
      color: #8e44ad;
    }

    .belize {
      color: #2980b9;
    }

    .pomegranate {
      color: #c0392b;
    }
    .orange {
      color: #ff7c00;
    }
    .blue-dark {
      color: #0d132f;
    }
    .blue {
      color: #0a88ff;
    }
    .green {
      color: #0aac49;
    }
    .midnight {
      color: #0d304b;
    }
  }
}
