// Blog listing and node view style.

// Listing page.

.pane__page_title {
  .page__title {
    font-size: $font-size-h4;
  }
}
.page__blog {
  .main__title {
    margin-bottom: 65px;
  }
  .pane__custom__blog_listing_title_text {
    p {
      font-size: $font-size-h4;
    }
  }
}
.categories,
.post_topic {
  .main__title {
    margin-bottom: 65px;
  }
  .pane__page_title {
    .page__title {
      font-size: 56px;
    }
  }
  .pane__entity_field_extra__description {
    p {
      font-size: $font-size-h4;
    }
  }
  .site__footer {
    margin-top: 25px;
  }
}
.content__teaser--cc-blog {
  // Content Header.
  .content__header {
    margin-top: 45px;
    @include breakpoint($screen-desktop) {
      margin-top: 65px;
    }
  }
  &:first-child {
    .content__header,
    .content__title {
      margin-top: 0;
    }
  }
  .content__title a {
    color: $blue-dark;
    font-family: $sofiapro;
    font-weight: 600;
    font-size: $font-size-h2;
    &:hover {
      text-decoration: underline;
    }
  }
  h1,
  h2,
  h3 {
    font-size: $font-size-h4;
  }
}
// Full Node.

.node-type-cc-blog {
  h1.page__title {
    color: $orange;
    font-size: 56px;
    font-family: $sofiapro;
    font-weight: 600;
  }
  .sidebar__wrapper {
    margin-top: 25px;
    @include breakpoint($screen-desktop) {
      margin-top: 0;
    }
  }
}
.content__full--cc-blog {
  h1 {
    color: $blue-dark;
    font-family: $sofiapro;
    font-size: $font-size-h4;
    font-weight: 600;
  }
}
// Content Body.
.content__body {
  margin-bottom: 45px;
}
.field_cc_blog_body {
  img {
    max-width: 100%;
    height: auto !important; // ensure images with inline width attribute are scaled proportionately
  }

  h2 {
    color: $blue-dark;
    font-family: $sofiapro;
    font-size: $font-size-h4;
    font-weight: 600;
  }

  ul {
    padding-left: $padding-large-horizontal;
  }
}
// Content Footer.

.content__footer {
  color: #fff;
  font-size: $font-size-small;
  background-color: $blue-dark;
}
.content__footer__label {
  color: $gray-light;
  font-size: $font-size-small;
  margin-bottom: 0;
}
.field_cc_blog_headshot {
  img {
    margin-left: 20px;
    margin-top: 20px;
    width: 90px;
  }
}

// #51 - Hide blog topics because /blog/ is 403 on prod
.field_cc_blog_post_topic {
  display: none;
}
