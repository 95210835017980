// About Us page

.page__5505 {
  .page__title{
    margin-top: 100px;
    text-align: left;
    color: $orange;
    font-size: 46px;
    @include breakpoint($screen-tablet){
      font-size: 56px;
    }
    font-family: $sofiapro;
  }
  .field_subtitle__custom_display{
    @include breakpoint($screen-tablet){
      width: 60%;
      text-align: center;
      margin: 10px auto;
    }
    font-size: $font-size-h4;
    line-height: $font-size-h3;
    font-weight: 300;
    padding-bottom: 50px;
  }
  .main__content__inner {

    .pane__panels_mini,
    .pane__custom {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}